<template>
  <div class="space-y-3 divide-y divide-surface-accented my-2">
    <section v-if="!isLocaleEn" class="italic">
      {{ $t("releaseNotes.onlyEnglish") }}
    </section>
    <section>
      <h2>v0.4.0 <time>2023-03-13</time></h2>
      <h3>Changes</h3>
      <ul>
        <li>Sidebar UI changes</li>
        <li>Updated to work with the new version of Fiksu Network</li>
      </ul>
    </section>
    <section>
      <h2>v0.3.6 <time>2021-04-12</time></h2>
      <h3>Fixes</h3>
      <ul>
        <li>Fixed session-related issues</li>
      </ul>
    </section>
    <section>
      <h2>v0.3.5 <time>2021-01-07</time></h2>
      <h3>Features</h3>
      <ul>
        <li>Draggable sidebar cards</li>
      </ul>
    </section>
    <section>
      <h2>v0.3.4 <time>2021-12-30</time></h2>
      <h3>Features</h3>
      <ul>
        <li>Collapsible sidebar cards</li>
      </ul>
      <h3>Changes</h3>
      <ul>
        <li>The active alarms card is removed on production for the time being</li>
      </ul>
    </section>
    <section>
      <h2>v0.3.3 <time>2021-12-29</time></h2>
      <h3>Features</h3>
      <ul>
        <li>Show "beta" under the logo to indicate the site is not yet feature complete</li>
      </ul>
    </section>
    <section>
      <h2>v0.3.2 <time>2021-12-13</time></h2>
      <h3>Features</h3>
      <ul>
        <li>Display in-app release notes</li>
      </ul>
      <h3>Changes</h3>
      <ul>
        <li>Updated default layers</li>
      </ul>
    </section>
    <section>
      <h2>v0.3.1 <time>2021-12-08</time></h2>
      <h3>Fixes</h3>
      <ul>
        <li>Fixed an issue with alarms loading before sites</li>
      </ul>
    </section>
    <section>
      <h2>v0.3.0 <time>2021-12-08</time></h2>
      <h3>Changes</h3>
      <ul>
        <li>Reworked session handling</li>
      </ul>
    </section>
    <section>
      <h2>v0.2.1 <time>2021-12-08</time></h2>
      <h3>Features</h3>
      <ul>
        <!-- prettier-ignore -->
        <li>Alarm listing in the sidebar, with alert icons on the map and more detailed descriptions under site popups</li>
        <li>Display frontend version in the sidebar</li>
      </ul>
      <h3>Changes</h3>
      <ul>
        <li>Store and restore map position and zoom level</li>
      </ul>
    </section>
    <section>
      <h2>v0.2.0 <time>2021-12-02</time></h2>
      <h3>Features</h3>
      <ul>
        <li>Initial numbered version</li>
      </ul>
    </section>
  </div>
</template>

<script>
export default {
  computed: {
    isLocaleEn() {
      return this.$store.state.preferences.locale === "en"
    },
  },
}
</script>

<style lang="postcss" scoped>
h1 {
  @apply text-xl uppercase font-bold;
}
h2 {
  @apply text-xl font-bold mt-1;
}
h2 > time {
  @apply text-muted text-sm font-normal;
}
h3 {
  @apply text-base font-semibold;
}
section {
  @apply text-sm pl-2 space-y-1;
}
ul {
  @apply list-disc list-inside pl-2;
}
</style>
