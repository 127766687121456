var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "space-y-3 divide-y divide-surface-accented my-2" },
    [
      !_vm.isLocaleEn
        ? _c("section", { staticClass: "italic" }, [
            _vm._v(" " + _vm._s(_vm.$t("releaseNotes.onlyEnglish")) + " ")
          ])
        : _vm._e(),
      _vm._m(0),
      _vm._m(1),
      _vm._m(2),
      _vm._m(3),
      _vm._m(4),
      _vm._m(5),
      _vm._m(6),
      _vm._m(7),
      _vm._m(8),
      _vm._m(9)
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("h2", [_vm._v("v0.4.0 "), _c("time", [_vm._v("2023-03-13")])]),
      _c("h3", [_vm._v("Changes")]),
      _c("ul", [
        _c("li", [_vm._v("Sidebar UI changes")]),
        _c("li", [
          _vm._v("Updated to work with the new version of Fiksu Network")
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("h2", [_vm._v("v0.3.6 "), _c("time", [_vm._v("2021-04-12")])]),
      _c("h3", [_vm._v("Fixes")]),
      _c("ul", [_c("li", [_vm._v("Fixed session-related issues")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("h2", [_vm._v("v0.3.5 "), _c("time", [_vm._v("2021-01-07")])]),
      _c("h3", [_vm._v("Features")]),
      _c("ul", [_c("li", [_vm._v("Draggable sidebar cards")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("h2", [_vm._v("v0.3.4 "), _c("time", [_vm._v("2021-12-30")])]),
      _c("h3", [_vm._v("Features")]),
      _c("ul", [_c("li", [_vm._v("Collapsible sidebar cards")])]),
      _c("h3", [_vm._v("Changes")]),
      _c("ul", [
        _c("li", [
          _vm._v(
            "The active alarms card is removed on production for the time being"
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("h2", [_vm._v("v0.3.3 "), _c("time", [_vm._v("2021-12-29")])]),
      _c("h3", [_vm._v("Features")]),
      _c("ul", [
        _c("li", [
          _vm._v(
            'Show "beta" under the logo to indicate the site is not yet feature complete'
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("h2", [_vm._v("v0.3.2 "), _c("time", [_vm._v("2021-12-13")])]),
      _c("h3", [_vm._v("Features")]),
      _c("ul", [_c("li", [_vm._v("Display in-app release notes")])]),
      _c("h3", [_vm._v("Changes")]),
      _c("ul", [_c("li", [_vm._v("Updated default layers")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("h2", [_vm._v("v0.3.1 "), _c("time", [_vm._v("2021-12-08")])]),
      _c("h3", [_vm._v("Fixes")]),
      _c("ul", [
        _c("li", [_vm._v("Fixed an issue with alarms loading before sites")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("h2", [_vm._v("v0.3.0 "), _c("time", [_vm._v("2021-12-08")])]),
      _c("h3", [_vm._v("Changes")]),
      _c("ul", [_c("li", [_vm._v("Reworked session handling")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("h2", [_vm._v("v0.2.1 "), _c("time", [_vm._v("2021-12-08")])]),
      _c("h3", [_vm._v("Features")]),
      _c("ul", [
        _c("li", [
          _vm._v(
            "Alarm listing in the sidebar, with alert icons on the map and more detailed descriptions under site popups"
          )
        ]),
        _c("li", [_vm._v("Display frontend version in the sidebar")])
      ]),
      _c("h3", [_vm._v("Changes")]),
      _c("ul", [
        _c("li", [_vm._v("Store and restore map position and zoom level")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", [
      _c("h2", [_vm._v("v0.2.0 "), _c("time", [_vm._v("2021-12-02")])]),
      _c("h3", [_vm._v("Features")]),
      _c("ul", [_c("li", [_vm._v("Initial numbered version")])])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }